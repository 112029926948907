var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},_vm._l((_vm.rooms),function(room){return _c('div',{key:room.id,staticClass:"card shadow col-md-4 mb-4 ml-2 hover-card",staticStyle:{"padding-top":"10px","max-width":"95% !important"}},[_c('router-link',{attrs:{"to":{
          name: 'EditRoom',
          path: ("/clients/" + (_vm.client.id) + "/rooms/" + (room.id)),
          params: {
            roomNameProp: room.roomType,
            roomProp: room,
            roomId: room.id,
            clientProp: _vm.client,
          },
        }}},[_c('div',[_c('h2',{staticStyle:{"color":"black","text-align":"center"}},[_vm._v(_vm._s(room.roomType))]),_c('p',{staticStyle:{"color":"black","text-align":"center","font-size":"20px"}},[_vm._v(" "+_vm._s(room.location)+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('p',{staticStyle:{"float":"right","color":"black","font-size":"1rem"}},[_c('u',[_vm._v("PSR ")])]),_c('p',{staticStyle:{"float":"right","color":"black","font-size":"1rem"}},[_c('u',[_vm._v("AR")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('p',{staticStyle:{"float":"left","font-size":"0.9rem","color":"black"}},[_vm._v("Initial")]),_c('p',{staticStyle:{"float":"right","color":"black","font-weight":"bold","font-size":"0.85rem"}},[_vm._v(" "+_vm._s(room.personalSafetyPreScore || room.personalSafetyPreScore == 0 ? Math.round(room.personalSafetyPreScore * 10) + "%" : "----")+" ")]),_c('p',{staticStyle:{"float":"right","color":"black","font-weight":"bold","font-size":"0.85rem"}},[_vm._v(" "+_vm._s(room.accessibilityPreScore || room.accessibilityPreScore == 0 ? Math.round(room.accessibilityPreScore.toFixed(0)) + "%" : "----")+" ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('p',{staticStyle:{"float":"left","font-size":"0.9rem","color":"black"}},[_vm._v(" Follow-Up ")]),_c('p',{staticStyle:{"float":"right","color":"black","font-weight":"bold","font-size":"0.85rem"}},[_vm._v(" "+_vm._s(room.personalSafetyPostScore || room.personalSafetyPostScore == 0 ? Math.round(room.personalSafetyPostScore * 10) + "%" : "----")+" ")]),_c('p',{staticStyle:{"float":"right","color":"black","font-weight":"bold","font-size":"0.85rem"}},[_vm._v(" "+_vm._s(room.accessibilityPostScore || room.accessibilityPostScore == 0 ? Math.round(room.accessibilityPostScore.toFixed(0)) + "%" : "----")+" ")])])])])])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }
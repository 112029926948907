<template>
  <div>
    <SecondNavBar />
    <main>
      <div class="card shadow col-lg-8 offset-lg-2" style="padding-top:30px;">
        <div class="row">
          <div class="col-lg-2">
            <router-link to="/clients"
              ><button class="btn purple-btn-outline btn-shadow mb-4">
                <FontAwesomeIcon icon="arrow-left" />&nbsp;&nbsp; Back
              </button></router-link
            >
          </div>
          <div class="col-lg-6 offset-lg-1">
            <h4 class="purple-title text-center">Client ID: {{ client.id }}</h4>
          </div>
          <div class="offset-lg-1 col-lg-2 text-center">
            <button
              :disabled="client.preventEdit"
              type="button"
              class="btn btn-danger btn-shadow mb-2"
              @click="showModal = true"
            >
              <FontAwesomeIcon icon="minus" />&nbsp;&nbsp;Delete
            </button>
            <Modal v-model="showModal" title="Delete Client">
              <p>Are you sure you would like to delete this client?</p>
              <button
                @click="showModal = false"
                class="btn purple-btn-outline btn-shadow"
              >
                <FontAwesomeIcon icon="arrow-left" /> &nbsp;&nbsp; Back
              </button>
              <button
                @click="deleteClient(client)"
                style="float:right;"
                class="btn btn-danger btn-shadow"
              >
                <FontAwesomeIcon icon="minus" />&nbsp;&nbsp;Delete
              </button>
            </Modal>
          </div>
        </div>
        <div class="row" style="margin-bottom:20px;">
          <div class="col-lg-2 offset-lg-1">
            <button @click="navigateToReport()" class=" mb-2 btn btn-success">
              <FontAwesomeIcon icon="file-download" /> Report
            </button>
          </div>
          <div class="col-lg-6 ">
            <div
              class=" bg-purple text-white text-center"
              style="padding-top:5px; padding-bottom:5px;"
            >
              <!-- <h5>Current Status: <span class="font-weight-bold">Assessment Scheduled/ Started</span></h5> -->
              <h5>
                Current Status:
                <span class="font-weight-bold">{{ getClientStatus }}</span>
              </h5>
            </div>
          </div>
          <div class=" col-lg-3 text-center mb-2">
            <button
              :disabled="client.preventEdit"
              v-if="client.status != 'Follow-Up Assessment Completed'"
              @click="showMarkStatusModal = true"
              class="btn btn-primary mt-1 btn-shadow"
            >
              <FontAwesomeIcon icon="check-circle" />&nbsp;&nbsp;{{
                getAssessmentButtonText
              }}
            </button>
          </div>
        </div>
        <div class="row" style="margin-bottom:30px;">
          <Modal v-model="showMarkStatusModal" title="Mark Status Complete">
            <p>
              Are you sure you would like to mark this client's status as
              complete?
            </p>
            <button
              @click="showMarkStatusModal = false"
              class="btn purple-btn btn-shadow"
            >
              <FontAwesomeIcon icon="arrow-left" /> &nbsp;&nbsp; Back
            </button>
            <button
              @click="markStatus()"
              style="float:right;"
              class="btn btn-success btn-shadow"
            >
              <FontAwesomeIcon icon="check-circle" />&nbsp;&nbsp;Mark Complete
            </button>
          </Modal>
        </div>
      </div>
      <div class="card shadow col-lg-8 offset-lg-2 mt-2">
        <div class="card-header bg-white">
          <div class="row">
            <h3 class="purple-title mb-2 ml-3 col-lg-7">
              Client Information: {{ client.streetName }}
            </h3>
            <button
              :disabled="client.preventEdit"
              style="float:right;"
              class="btn btn-success ml-4 mb-4 btn-shadow"
              @click="saveClientChanges(client)"
            >
              <FontAwesomeIcon icon="save" /> &nbsp;&nbsp;Save Changes
            </button>
          </div>
          <div class="row mb-5">
            <div class="col-lg-12">
              <button
                @click="currentTab = 'general'"
                v-bind:class="
                  currentTab == 'general'
                    ? 'btn btn-secondary'
                    : 'btn btn-outline-secondary'
                "
                style="border:0 !important;"
              >
                1.) General Info
              </button>
              <button
                @click="currentTab = 'special'"
                v-bind:class="
                  currentTab == 'special'
                    ? 'btn btn-secondary'
                    : 'btn btn-outline-secondary'
                "
                style="border:0 !important"
              >
                2.) Special Considerations
              </button>
              <button
                @click="currentTab = 'concerns'"
                v-bind:class="
                  currentTab == 'concerns'
                    ? 'btn btn-secondary'
                    : 'btn btn-outline-secondary'
                "
                style="border:0 !important"
              >
                3.) Concerns List
              </button>
              <button
                @click="currentTab = 'fall'"
                v-bind:class="
                  currentTab == 'fall'
                    ? 'btn btn-secondary'
                    : 'btn btn-outline-secondary'
                "
                style="border:0 !important"
              >
                4.) Fall Information
              </button>
              <button
                @click="currentTab = 'summary'"
                v-bind:class="
                  currentTab == 'summary'
                    ? 'btn btn-secondary'
                    : 'btn btn-outline-secondary'
                "
                style="border:0 !important"
              >
                5.) Client Summary
              </button>
            </div>
          </div>
        </div>
        <div
          id="general-info-client-tab"
          class="mt-4"
          v-show="currentTab == 'general'"
        >
          <div class="row mb-4">
            <label class="col-lg-3 offset-lg-1" for="referralReason"
              >Referral Reason (1,500 characters):</label
            >
            <div class="col-lg-8">
              <textarea
                maxlength="150"
                id="referralReason"
                :disabled="client.preventEdit"
                v-model="client.referralReason"
                class="form-control"
              ></textarea>
            </div>
          </div>
          <div class="row mb-4">
            <label class="col-lg-2 offset-lg-1" for="tenantType"
              >Tenant Type:</label
            >
            <div class="col-lg-4">
              <input
                id="tenantType"
                :disabled="client.preventEdit"
                class="form-control"
                v-model="client.tenantType"
              />
            </div>
            <label class="col-lg-3" for="has-hoa-select"
              >Part of Homeowner's Association?</label
            >
            <div class="col-lg-1">
              <input
                :disabled="client.preventEdit"
                type="checkbox"
                id="has-hoa-select"
                @click="setHasHoa()"
                :checked="client.hasHoa == 'yes' ? true : false"
              />
            </div>
          </div>
          <div class="row mb-4">
            <label class="col-lg-2 offset-lg-1" for="housingType"
              >Housing Type:</label
            >
            <div class="col-lg-4">
              <select
                id="housingType"
                :disabled="client.preventEdit"
                class="form-control"
                v-model="client.housingType"
              >
                <option value="apartment/condo">Apartment/Condo</option>
                <option value="singlefamily">Single Family</option>
                <option value="townhouse/duplex">Townhouse/Duplex</option>
                <option value="module/mobile">Modular/Mobile</option></select
              >
            </div>
            <label class="col-lg-2" for="streetName">Street Name:</label>
            <div class="col-lg-3">
              <input
                id="streetName"
                :disabled="client.preventEdit"
                class="form-control"
                v-model="client.streetName"
              />
            </div>
          </div>
          <div class="row mb-4">
            <label class="col-lg-2 offset-lg-1" for="numberOfFloors"
              >No. of Floors:</label
            >
            <div class="col-lg-2">
              <select
                id="numberOfFloors"
                :disabled="client.preventEdit"
                class="form-control"
                v-model="client.numberOfFloors"
                ><option value="1">1</option
                ><option value="2">2</option
                ><option value="3">3</option
                ><option value="4">4</option
                ><option value="5">5</option>
              </select>
            </div>
          </div>
        </div>
        <div v-show="currentTab == 'special'" class="mt-4">
          <div class="row mb-4">
            <label class="col-lg-2 offset-lg-1" for="height"
              >Height (inches):</label
            >
            <div class="col-lg-2">
              <input
                id="height"
                :disabled="client.preventEdit"
                class="form-control"
                v-model="client.height"
              />
            </div>
            <label class="col-lg-2 offset-lg-1" for="weight"
              >Weight (lbs):</label
            >
            <div class="col-lg-2">
              <input
                id="weight"
                :disabled="client.preventEdit"
                class="form-control"
                v-model="client.weight"
              />
            </div>
          </div>
          <div class="row mb-4">
            <label class="col-lg-2 offset-lg-1" for="isVeteran">Veteran?</label>
            <div class="col-lg-1">
              <select
                id="isVeteran"
                :disabled="client.preventEdit"
                class="form-control"
                v-model="client.isVeteran"
                ><option value="yes">Yes</option
                ><option value="no">No</option></select
              >
            </div>
            <label class="col-lg-2 offset-lg-1" for="livesWith"
              >Lives With:</label
            >
            <div class="col-lg-3">
              <select
                id="livesWith"
                :disabled="client.preventEdit"
                v-model="client.livesWith"
                class="form-control"
              >
                <option value="alone">Self/Alone</option>
                <option value="spouse">Spouse</option>
                <option value="children">Child(ren)</option>
                <option value="spouseandchildren">Spouse & child(ren)</option>
                <option value="parents">Parent(s)</option>
                <option value="siblings">Sibling(s)</option>
                <option value="other">Other Family</option>
                <option value="roomate">Roomate</option>
              </select>
            </div>
          </div>
          <div class="row mb-4">
            <label class="col-lg-2 offset-lg-1" for="referredBy"
              >Referred By:</label
            >
            <div class="col-lg-2">
              <input
                id="referredBy"
                :disabled="client.preventEdit"
                v-model="client.referralSource"
                class="form-control"
              />
            </div>
          </div>
          <div class="row mb-4">
            <label class="col-lg-2 offset-lg-1" for="inaccessibleAreas"
              >Inaccessible Areas:</label
            >
            <div class="col-lg-8">
              <textarea
                maxlength="150"
                id="inaccessibleAreas"
                :disabled="client.preventEdit"
                class="form-control"
                v-model="client.inaccessibleAreas"
              ></textarea>
            </div>
          </div>
          <div class="row mb-4">
            <label class="col-lg-2 offset-lg-1" for="assistiveDevices"
              >Assistance Devices:</label
            >
            <div class="col-lg-8">
              <textarea
                maxlength="150"
                id="assistiveDevices"
                :disabled="client.preventEdit"
                class="form-control"
                v-model="client.assistiveDevices"
              ></textarea>
            </div>
          </div>
        </div>
        <div v-show="currentTab == 'concerns'" class="mt-4">
          <div class="row mb-4">
            <div class="col-lg-9  offset-lg-1">
              <p>
                Select an item from the dropdown list and enter a note where
                applicable. Select the Add to List button to add the concern.
              </p>
            </div>
          </div>
          <div class="container-fluid">
            <div class="row mb-4">
              <label class="col-lg-2 offset-lg-1" for="concerns">
                Concerns:
              </label>
              <select
                id="concerns"
                :disabled="client.preventEdit"
                v-model="createSpecialConcernModel.considerationName"
                class="col-lg-4 offset-lg-1 form-control"
              >
                <option value="Has a History of Falls"
                  >Has a History of Falls</option
                >
                <option value="Has a Caregiver">Has a Caregiver</option>
                <option value="Has Memory Impairment"
                  >Has Memory Impairment</option
                >
                <option value="Has Neurological Issues"
                  >Has Neurological Issues</option
                >
                <option value="Has Orthopedic Issues"
                  >Has Orthopedic Issues</option
                >
                <option value="Has Visual Defects">Has Visual Defects</option>
                <option value="Has Pets">Has Pets</option>
                <option value="Other Concern 1">Other Concern 1</option>
                <option value="Other Concern 2">Other Concern 2</option>
                <option value="Other Concern 3">Other Concern 3</option>
                <option value="Other Concern 4">Other Concern 4</option>
                <option value="Other Concern 5">Other Concern 5</option>
              </select>
            </div>
            <div class="row mb-4">
              <label class="col-lg-2 offset-lg-1" for="concernDetails">
                Details:
              </label>
              <textarea
                id="concernDetails"
                :disabled="client.preventEdit"
                v-model="createSpecialConcernModel.note"
                class="col-lg-4 form-control offset-lg-1"
              ></textarea>
            </div>

            <div class="vld-parent">
              <loading
                :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"
              ></loading>
            </div>
            <div class="row mb-4">
              <button
                :disabled="client.preventEdit"
                @click="saveConcern(client, createSpecialConcernModel)"
                class="btn purple-btn btn-shadow col-lg-2 offset-lg-5"
              >
                Add Concern
              </button>
            </div>
            <ConcernsTable :items="concerns" @set-new-items="setNewItems" />
          </div>
        </div>
        <div v-if="currentTab == 'fall'" class="mt-4">
          <div class="row mb-4">
            <p class="offset-lg-1 col-lg-3">
              Did the last fall for the client:
            </p>
          </div>
          <div class="row mb-4">
            <label class="col-lg-3 offset-lg-1" for="resultInHospitalization"
              >Result in Hospitalization?</label
            >
            <div class="col-lg-2">
              <select
                id="resultInHospitalization"
                :disabled="client.preventEdit"
                v-model="client.fallHospitalization"
                class="form-control"
                ><option value="yes">yes</option
                ><option value="no">no</option></select
              >
            </div>
            <label class="col-lg-3 offset-lg-1" for="resultInRehab"
              >Result in Rehabilitation?</label
            >
            <div class="col-lg-2">
              <select
                id="resultInRehab"
                :disabled="client.preventEdit"
                v-model="client.fallRehab"
                class="form-control"
                ><option value="yes">yes</option
                ><option value="no">no</option></select
              >
            </div>
          </div>
          <div class="row mb-4">
            <label class="col-lg-3 offset-lg-1" for="resultInInjury"
              >Result in an Injury?</label
            >
            <div class="col-lg-2">
              <select
                id="resultInInjury"
                :disabled="client.preventEdit"
                class="form-control"
                v-model="client.fallInjury"
                ><option value="yes">yes</option>
                <option value="no">no</option>
              </select>
            </div>
            <label class="col-lg-3 offset-lg-1" for="fallLocation"
              >Where did the Fall Occur?</label
            >
            <div class="col-lg-2">
              <select
                id="fallLocation"
                :disabled="client.preventEdit"
                class="form-control"
                v-model="client.fallLocation"
              >
                <option value="N/a">N/a</option>
                <option value="Inside the Home">Inside the Home</option>
                <option value="Outside the Home">Outside the Home</option>
              </select>
            </div>
          </div>
          <div class="row mb-4">
            <label class="col-lg-3 offset-lg-1" for="fallRoom"
              >If inside, in which room did it occur?</label
            >
            <div class="col-lg-3">
              <select
                :disabled="client.preventEdit"
                class="form-control"
                v-model="client.fallRoom"
                id="fallRoom"
              >
                <option value="N/a">N/a</option>
                <option value="Bathroom">Bathroom</option>
                <option value="Bedroom">Bedroom</option>
                <option value="Dining Space">Dining Space</option>
                <option value="Entryway">Entryway</option>
                <option value="Kitchen">Kitchen</option>
                <option value="Living Room">Living Room</option>
                <option value="Hallway">Hallway</option>
                <option value="Outdoor Space">Outdoor Space</option>
                <option value="Stairs">Stairs</option>
                <option value="Laundry Room">Laundry Room</option>
              </select>
            </div>
            <label class="col-lg-2" for="fallCause">Cause of the fall?</label>
            <div class="col-lg-3">
              <select
                id="fallCause"
                :disabled="client.preventEdit"
                class="form-control"
                v-model="client.fallCauseType"
              >
                <option value="N/a">N/a</option>
                <option value="Environmental (e.g. Trip on Step)"
                  >Environmental (e.g. Trip on Step)</option
                >
                <option value="Physiological (e.g. Black Out, Disoriented)"
                  >Physiological (e.g. Black Out, Disoriented)</option
                >
              </select>
            </div>
          </div>
          <div class="row mb-4">
            <label class="col-lg-5 offset-lg-1" for="fallComments">
              Fall Comments (1500 characters)</label
            >
          </div>
          <div class="row mb-4">
            <div class="col-lg-9 offset-lg-1">
              <textarea
                id="fallComments"
                :disabled="client.preventEdit"
                v-model="client.fallCauseComments"
                class="form-control"
              ></textarea>
            </div>
          </div>
        </div>
        <div v-show="currentTab == 'summary'" class="mt-4">
          <div class="row mb-4">
            <div class="col-lg-12">
              <div class="row mb-4">
                <div class="col-lg-12">
                  <label for="clientSummary">Client Summary:</label>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-lg-12">
                  <textarea
                    id="clientSummary"
                    v-if="client.preventEdit"
                    disabled
                    class="form-control"
                    style="height:250px;"
                    v-html="client.note"
                  ></textarea>
                  <RichTextEditor
                    :editorProp="editor1"
                    :contentProp="strProp"
                    :numberProp="1"
                    v-else
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="row mb-4">
                <div class="col-lg-12">
                  <label for="additionalRecommendations"
                    >Additional Recommendations:</label
                  >
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-lg-12">
                  <textarea
                    id="additionalRecommendations"
                    disabled
                    v-if="client.preventEdit"
                    style="height:250px;"
                    class="form-control"
                    v-html="client.clientRecommendations"
                  ></textarea>
                  <RichTextEditor
                    :editorProp="editor2"
                    :contentProp="client.clientRecommendations"
                    :numberProp="2"
                    v-else
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            :can-cancel="false"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
      <!-- bottom room cards row -->
      <div class="col-lg-8 offset-lg-2 mt-2">
        <div class="row mb-4 mt-4" id="client-rooms">
          <h3 class="purple-title mt-4 col-md-4">Client Rooms</h3>
          <div
            class=" col-lg-4 mt-4 text-center"
            v-if="
              client.status == 'Initial Assessment Started' ||
                client.status == 'Initial Assessment Completed'
            "
          >
            <label style="display:none;" id="createRoomLabel"
              >Create Room</label
            >
            <select
              aria-labelledby="createRoomLabel"
              :disabled="client.preventEdit"
              class="form-control mb-3"
              v-model="room.roomType"
              style="width:75%;"
              @change="handleRoomSelection(room.roomType)"
            >
              <option value="">Select New Room</option>
              <option value="Bathroom">Bathroom</option>
              <option value="Bedroom">Bedroom</option>
              <option value="Dining Space">Dining Space</option>
              <option value="Entryway">Entryway</option>
              <option value="Kitchen">Kitchen</option>
              <option value="Living Room">Living Room</option>
              <option value="Hallway">Hallway</option>
              <option value="Outdoor Space">Outdoor Space</option>
              <option value="Stairs">Stairs</option>
              <option value="Laundry Room">Laundry Room</option>
              <option value="Custom">Custom</option>
            </select>
          </div>
          <div v-else class="col-lg-3 mt-4"></div>
          <div
            class="col-lg-3 mt-4"
            v-if="
              client.status == 'Initial Assessment Started' ||
                client.status == 'Initial Assessment Completed'
            "
          >
            <button
              style="float:left;"
              id="add-room-button"
              :disabled="room.name == ''"
              class="btn btn-primary btn-shadow mb-4"
              @click="handleNewRoomClick"
            >
              <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;New Room</button
            ><br />
            <!-- </router-link> -->
          </div>
          <!-- <Modal v-model="showCustomRoomModal" title="Enter Room Name:" class="custom-modal-bg">
            <div class="modal-content text-center">
              <input type="text" maxlength="20" class="form-control my-2" />
              <p>20 character limit</p>
              <button
                @click="showCustomRoomModal = false"
                class="btn custom-save-btn"
              >
                <FontAwesomeIcon icon="save" /> &nbsp;&nbsp; Save
              </button>
            </div>
          </Modal> -->
        </div>
        <RoomCardRow :rooms="rooms" :client="client" />
        <div
          class="modal fade"
          id="customRoomModal"
          ref="customRoomModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div
              class="modal-content custom-modal bg-purple text-white text-center rounded-lg"
            >
              <div class="modal-header">
                <h5 class="modal-title" id="modalLabel">Enter Room Name:</h5>
                <button
                  type="button"
                  class="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeCustomRoomModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                <input
                  id="customRoomName"
                  type="text"
                  class="form-control mb-2"
                  maxlength="20"
                  v-model="customRoomName"
                />

                <p>20 character limit</p>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button
                  type="button "
                  class="btn custom-save-btn text-white btn-success"
                  data-dismiss="modal"
                  @click="saveCustomRoomAndRedirect"
                >
                  <FontAwesomeIcon icon="save" />
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import SecondNavBar from "../../components/Clients/SecondNavBar";
import router from "../../router";
import { clientService } from "../../_services";
import { roomService } from "../../_services/index";
import { Editor } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";
import ConcernsTable from "../../components/Clients/ConernsTable";
import Loading from "vue-loading-overlay";
import RoomCardRow from "../../components/Rooms/RoomCardRow";
import "vue-loading-overlay/dist/vue-loading.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import RichTextEditor from "../../components/Shared/RichTextEditor";
import TextAlign from "@tiptap/extension-text-align";
import { config } from "../../../config";
import Link from '@tiptap/extension-link'

export default {
  components: {
    SecondNavBar,
    ConcernsTable,
    RoomCardRow,
    RichTextEditor,
    Modal: VueModal,
    Loading,
    FontAwesomeIcon,
  },
  mounted() {
    this.isLoading = true;
    clientService.getClientById(this.$route.params.id).then(
      (response) => {
        this.client = response.data;
        this.strProp = this.client.note;
        this.editor1 = new Editor({
          extensions: [
            StarterKit,
            TextAlign.configure({
              types: ["heading", "paragraph"],
            }),
            Link.configure({ openOnClick: true })
          ],
          content: this.client.note,
        });
        this.editor2 = new Editor({
          extensions: [
            StarterKit,
            Link.configure({ openOnClick: true })
          ],
          content: this.client.clientRecommendations,
        });
        localStorage.setItem("CurrentClient", JSON.stringify(this.client));

        roomService.getAllClientRooms(this.client.id).then((response) => {
          this.rooms = response.data;
        });
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        router.push("/clients");
      }
    );

    document.body.scrollTop = document.documentElement.scrollTop = 0;
    clientService.getAllClientConcerns(this.$route.params.id).then(
      (response) => {
        for (var i = 0; i < response.data.length; i++) {
          response.data[i].concernKey = clientService.getConcernNameFromKey(
            response.data[i].concernKey
          );
        }
        this.concerns = response.data;
      },
      () => {
        router.push("/clients");
      }
    );

    if (localStorage.getItem("firstRoomLoad")) {
      window.location.href = "#client-rooms";
      localStorage.removeItem("firstRoomLoad");
    }
  },
  computed: {
    getClientStatus: function() {
      return this.client.status;
    },
    getAssessmentButtonText: function() {
      switch (this.client.status) {
        case "Initial Assessment Started":
          return "Mark Initial Assessment Complete";
        case "Initial Assessment Completed":
          return "Start Follow-Up Assessment";
        case "Follow-Up Assessment Started":
          return "Mark Follow-Up Assessment Complete";
        default:
          return "Follow-Up Assessment Completed";
      }
    },
  },
  data() {
    return {
      firstAssessment: {},
      lastAssessment: {},
      calendarConfigs: {
        sundayStart: false,
        dateFormat: "mm/dd/yyyy",
        isDatePicker: true,
        isDateRange: false,
        isModal: true,
      },
      customRoomName: "",
      reportBaseUrl: config.reportUrl,
      editor1: null,
      editor2: null,
      currentTab: "general",
      isLoading: false,
      fullPage: true,
      showModal: false,
      showCustomRoomModal: false,
      showMarkStatusModal: false,
      concerns: [],
      isCustom: false,

      //     currentTab: "general",
      // add in client considerations to cliennt
      room: {
        id: null,
        roomType: "",
        clientId: "",
        location: "",
        roomGoals: "",
        preAssessmentNotes: "",
        postAssessmentNotes: "",
        personalSafetyPreScore: null,
        personalSafetyPostScore: null,
        accessibilityPreScore: null,
        accessibilityPostScore: null,
        createdOn: null,
        updatedOn: null,
        status: "Initial Assessment Started",
        roomProperties: [],
        roomRecommendations: [],
      },
      client: {
        referralReason: "",
        preventEdit: false,
        tenantType: "",
        hasHoa: "",
        housingType: "",
        streetName: "",
        numberOfFloors: "",
        email: "",
        gender: "",
        height: "",
        weight: "",
        isVeteran: "",
        livesWith: "",
        referralSource: "",
        inaccessibleAreas: "",
        assistiveDevices: "",
        fallHospitalization: "",
        fallRehab: "",
        fallInjury: "",
        fallLocation: "N/a",
        fallRoom: "N/a",
        fallCauseType: "N/a",
        fallCauseComments: "",
        note: "",
        status: "",
        reportCode: "",
        clientRecommendations: "",
      },
      clientSpecialConsiderations: [],
      createSpecialConcernModel: {
        considerationName: "",
        note: "",
        clientId: "",
      },
      rooms: [],
      strProp: "",
    };
  },
  methods: {
    saveCustomRoom() {
      // Logic to save the custom room
      this.showCustomRoomModal = false;
    },
    emailProductNavigation: function() {
      window.location.href =
        "https://www.homeforlifedesign.com/#!professional-room-selection/c6or";
    },
    deleteClient: function(client) {
      this.isLoading = true;
      clientService.deleteClient(client).then(() => {
        this.isLoading = false;
        this.showModal = false;
        router.push("/clients");
        this.$toast.success(
          "Successfully deleted client!",
          {
            position: "bottom-center",
            timeout: 5000,
            hideProgressBar: true,
          },
          () => {
            this.isLoading = false;
            this.$toast.error("Unable to delete client.", {
              position: "bottom-center",
              timeout: 5000,
              hideProgressBar: true,
            });
          }
        );
      });
    },
    markStatus() {
      clientService.updateStatus(this.client).then(
        (response) => {
          console.log('success!');
          this.showMarkStatusModal = false;
          this.$toast.success("Successfully updated status.", {
            position: "bottom-center",
            timeout: 5000,
            hideProgressBar: true,
          });
          this.client = response.data;
          window.location.reload();
        },
        () => {
          this.showMarkStatusModal = false;
          this.$toast.error("Failed to update status.", {
            position: "bottom-center",
            timeout: 5000,
            hideProgressBar: true,
          });
        }
      );
    },
    setNewItems(e) {
      this.concerns = e;
    },
    saveClientChanges: function(client) {
      this.isLoading = true;
      //  client.finalAssessment = this.lastAssessment.selectedDate;
      var clientNote = document.getElementById("rich-editor").innerHTML;
      var recommendation = document.getElementById("rich-editor2").innerHTML;
      this.client.note = clientNote;
      this.client.clientRecommendations = recommendation;
      clientService.editClient(client).then(
        () => {
          this.isLoading = false;
          this.$toast.success("Successfully saved changes.", {
            position: "bottom-center",
            timeout: 5000,
            hideProgressBar: true,
          });
        },
        () => {
          this.isLoading = false;
          this.$toast.error("Failed to save changes.", {
            position: "bottom-center",
            timeout: 5000,
            hideProgressBar: true,
          });
        }
      );
    },
    handleRoomSelection(selectedRoom) {
      this.setLocalStorageCreateRoom(selectedRoom);
    },
    handleNewRoomClick() {
      if (this.room.roomType === "Custom") {
        this.openCustomRoomModal();
      } else {
        this.redirectToCreateRoom();
      }
    },
    saveCustomRoomAndRedirect() {
      this.room.roomType = this.customRoomName;
      this.isCustom = true;
      this.room.clientId = this.client.id;
      this.handleRoomSelection(this.customRoomName);
      this.$router.push({
        name: "CreateRoom",
        params: {
          roomNameProp: this.customRoomName,
          clientProp: this.client,
          isCustomProp: this.isCustom,
        },
      });
    },
    openCustomRoomModal() {
      let modal = this.$refs.customRoomModal;
      modal.style.display = "block";
      modal.classList.add("show");
    },
    closeCustomRoomModal() {
      let modal = this.$refs.customRoomModal;
      modal.style.display = "none";
      modal.classList.remove("show");
    },
    redirectToCreateRoom() {
      // Logic to redirect to the 'CreateRoom' route
      this.$router.push({
        name: "CreateRoom",
        params: {
          roomNameProp: this.room.name,
          clientProp: this.client,
          isCustomProp: this.isCustom,
        },
      });
    },
    setLocalStorageCreateRoom(roomName) {
      localStorage.setItem("CreateRoomFormName", roomName);
    },
    setHasHoa() {
      if (event.target.checked) this.client.hasHoa = "yes";
      else this.client.hasHoa = "no";
    },
    navigateToReport() {
      window.open(
        this.reportBaseUrl +
          "report/clientreport?code=" +
          this.client.reportCode,
        "_blank"
      );
    },
    saveConcern: function(client, concernModel) {
      if (this.createSpecialConcernModel.considerationName) {
        this.isLoading = true;
        concernModel.clientId = client.id;

        clientService.saveConcern(concernModel).then((response) => {
          // response.data.considerationName = clientService.getConcernNameFromKey(response.data.considerationName)
          this.concerns.push(response.data);
          this.isLoading = false;
          this.$toast.success(
            "Successfully added Concern!",
            {
              position: "bottom-center",
              timeout: 5000,
              hideProgressBar: true,
            },
            () => {
              this.isLoading = false;
              this.$toast.error("Unable to add concern.", {
                position: "bottom-center",
                timeout: 5000,
                hideProgressBar: true,
              });
            }
          );
          this.createSpecialConcernModel.note = "";
          this.createSpecialConcernModel.considerationName = "";
        });
      }
    },
  },
};
</script>

<style>
.bg-purple {
  background-color: purple;
}
/* Basic editor styles */
.ProseMirror {
  margin-top: 1rem;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  mark {
    background-color: #faf594;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
</style>
